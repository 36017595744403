import React from 'react';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Logo } from '../../icons';
import { BaseContainer } from '../baseComponents';
import {
  borders,
  colors,
  fonts,
  mq,
  DottedBackground,
} from '../../styles/theme';
import SocialLinks from '../SocialLinks';
import {
  HomePagePersonInfoFragment,
  NavigationInfoFragment,
} from '../../../gatsby-graphql';
import LocaleToggle from '../LocaleToggle';

interface ILandingHeroProps {
  personInfo?: HomePagePersonInfoFragment | null;
  siteNavigation?: NavigationInfoFragment | null;
  locale: string;
  pagePathName: string;
}

const LandingHero: React.FC<ILandingHeroProps> = ({
  personInfo,
  siteNavigation,
  locale,
  pagePathName,
}) => {
  return (
    <Content>
      <BackgroundContainer>
        <ColorBackground />
        <DottedBackgroundContainer />
      </BackgroundContainer>
      <LandingHeader>
        <StyledLogo />
        <LocaleToggle {...{ locale, pagePathName }} className="locale-toggle" />
      </LandingHeader>
      <Container>
        <ImageContainer>
          <StyledGatsbyImage
            loading="eager"
            fluid={personInfo?.profileImage?.fluid as FluidObject}
            draggable={false}
            durationFadeIn={150}
          />
        </ImageContainer>
        <InfoContainer>
          <Description>
            {documentToReactComponents(personInfo?.description?.json, {})}
          </Description>
          <LinksContainer>
            {siteNavigation?.navigation?.map((item) => {
              if (!item) return null;
              return (
                <StyledLink
                  key={String(item.link)}
                  to={`/${locale}${item.link}`}
                >
                  {item.name}
                </StyledLink>
              );
            })}
          </LinksContainer>
        </InfoContainer>
      </Container>
      <LandingFooter>
        <SocialLinks />
        <p>
          {personInfo?.name} © {new Date().getFullYear()}
        </p>
      </LandingFooter>
    </Content>
  );
};

const LandingHeader = styled(BaseContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  .locale-toggle {
    ${mq.untilTablet} {
      background-color: #171717;
      a {
        color: ${colors.surface};
        border-color: ${colors.surface};
      }
    }
  }
`;

const LandingFooter = styled(BaseContainer)`
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  display: flex;
  justify-content: space-between;
  a {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
    svg {
      fill: #171717;
      height: 24px;
      ${mq.desktop} {
        fill: ${colors.surface};
      }
    }
  }
  p {
    margin: 0;
    font-family: ${fonts.sansSerif};
    background: ${colors.surface};
  }
`;

const StyledLogo = styled(Logo)`
  fill: ${colors.surface};
  height: 40px;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: -1;
  ${mq.desktop} {
    flex-direction: row;
  }
`;

const ColorBackground = styled.div`
  flex: 2;
  background-color: #171717;
  ${mq.desktop} {
    flex: 3;
  }
`;

const DottedBackgroundContainer = styled.div`
  ${DottedBackground};
  flex: 5;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  z-index: 0;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Container = styled(BaseContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 4rem 0;
  ${mq.desktop} {
    margin: 0 auto;
    justify-content: flex-start;
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 240px;
  ${mq.tablet} {
    max-width: 360px;
  }
  ${mq.desktop} {
    max-width: unset;
    flex: 0 0 460px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  ${borders.radius};
  background: ${colors.surface};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
`;

const InfoContainer = styled.div`
  margin: 3rem 0;
  ${mq.tablet} {
    margin: 4rem 0;
    max-width: 512px;
  }
  ${mq.desktop} {
    margin: 0 0 0 4rem;
    max-width: unset;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  ${mq.desktop} {
    margin-top: 0;
    align-items: flex-start;
  }
  h1 {
    font-family: ${fonts.sansSerif};
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 24px;
    font-weight: bold;
    letter-spacing: 0;
    background: ${colors.surface};
    ${mq.desktop} {
      font-size: 6rem;
      line-height: 6rem;
      margin-bottom: 36px;
    }
  }
  p {
    font-size: 18px;
    line-height: 26px;
    color: ${colors.text.darkGrey};
    font-weight: 400;
    font-family: ${fonts.sansSerif};
    background: ${colors.surface};
    margin-bottom: 0;
    text-align: center;
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
    ${mq.desktop} {
      text-align: left;
    }
  }
  a {
    color: inherit;
  }
`;

const LinksContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mq.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
  ${mq.desktop} {
    justify-content: flex-start;
  }
`;

const StyledLink = styled(Link)`
  ${borders.radius};
  width: 60%;
  background: ${colors.surface};
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.09);
  margin: 0 auto 1rem;
  padding: 0.5rem 1.5rem;
  font-family: ${fonts.sansSerif};
  color: inherit;
  text-decoration: none;
  text-align: center;
  transition: 400ms ease-in-out;
  &:hover {
    background-color: #171717;
    color: ${colors.surface};
  }
  ${mq.tablet} {
    width: auto;
    margin: 0;
    &:not(:last-of-type) {
      margin: 0 2rem 0 0;
    }
  }
`;

export default LandingHero;
