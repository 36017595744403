import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import LandingHero from '../components/LandingHero';
import { HomePageQuery } from '../../gatsby-graphql';
import { IPage } from '../types';

const HomePage: React.FC<IPage<HomePageQuery>> = ({
  data: { personInfo, siteNavigation, footerSocialLinkLabel },
  pageContext: { locale },
  location: { pathname },
}) => {
  return (
    <Layout
      pagePathName={pathname}
      withHeader={false}
      withFooter={false}
      withBottomSpacing={false}
      pageDescription={String(personInfo?.seoDescription)}
      pageImage={String(personInfo?.seoImage?.file?.url)}
      lang={locale}
      siteNavigation={siteNavigation}
      footerSocialLabel={footerSocialLinkLabel?.value as string}
    >
      <LandingHero
        {...{ personInfo, siteNavigation, locale }}
        pagePathName={pathname}
      />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query HomePage($locale: String!) {
    ...PageInfoFragment
    personInfo: contentfulPerson(node_locale: { eq: $locale }) {
      ...HomePagePersonInfo
    }
  }
`;
